import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import HeadMetas from '../../common/HeadMetasContainer';
import PublicTitleDropdown from '../../common/PublicTitleDropdownContainer';
import PublicThermometer from '../../common/PublicThermometer';
import SocialSharing from '../../common/SocialSharingContainer';
import PublicParticipantPanel from '../components/PublicParticipantPanel';
import HelpText from '../../common/HelpText';
import PublicParticipantTeamMembersPanel from '../components/PublicParticipantTeamMembersPanelContainer';
import PublicDonationPanel from '../../common/PublicDonationPanelContainer';
import PublicCampaignBand from '../../common/PublicCampaignBand';
import PublicPageClosed from '../../common/PublicPageClosed';
import PublicPageMobileScripts from '../../common/PublicPageMobileScripts';
import Error404Page from '../../chore/Error404Page';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import { getPlatformUrl } from 'Parts/common/environment';
import { CAMPAIGN_TEMPLATE_SOLO, CAMPAIGN_TEMPLATE_TEAMS } from '../../campaign/campaignUtils';
import { PARTICIPANT_STATUS_ACTIVE } from '../participantUtils';
import { getLanguageKeyByName } from 'Parts/common/langUtils';

import ParticipantPublicPage_en from './locales/ParticipantPublicPage_en.json';
import ParticipantPublicPage_fr from './locales/ParticipantPublicPage_fr.json';

import './ParticipantPublicPage.less';

class ParticipantPublicPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ParticipantPublicPage', ParticipantPublicPage_en);
    i18next.addResourceBundle('fr', 'ParticipantPublicPage', ParticipantPublicPage_fr);
  }

  sortParticipants = participants => {
    return participants.sort(function(a, b) {
      return Number(get(b, 'collected', 0).toString().replace('NaN', 0)) - Number(get(a, 'collected', 0).toString().replace('NaN', 0));
    });
  };

  render() {
    const {
      t,
      locale,
      campaign,
      participant,
      team,
      location,
      platformAlias,
      userId,
      memberId,
      platform
    } = this.props;

    const settingsThermometer = get(campaign, 'settings.thermometer.behavior');
    const showThermometer = settingsThermometer === 'all' || settingsThermometer === 'children';

    const isCampaignClosed = campaign && campaign.statusId === 4 ? true : false;

    const isRegistrationDisabled =
      campaign &&
      campaign.settings &&
      campaign.settings.registration &&
      campaign.settings.registration.disabledOn
        ? isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
        : false;

    const isDonationsDisabled =
      campaign &&
      campaign.settings &&
      campaign.settings.donation &&
      campaign.settings.donation.disabledOn
        ? isDateBeforeCurrentDate(campaign.settings.donation.disabledOn, true)
        : false;


    if(participant.statusId !== PARTICIPANT_STATUS_ACTIVE || (campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS && (!participant.teamId || !team || !team.name))) 
      return <Error404Page />   


    const bcTeam = (
      <li>
        <Link to={`/${locale}/${participant.campaignAlias}/${participant.teamAlias}`}>
          {team.name}
        </Link>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </li>);

    const dummyBreadcrumbs = (
      <div className="ParticipantPublicPage__Breadcrumbs FundkyWrap globalDesktopOnly">
        <ul>
          <li>
            <Link to={`/${locale}`}>{t('home')}</Link>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </li>
          <li>
            <Link to={`/${locale}/${participant.campaignAlias}`}>{campaign.name}</Link>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </li>
          {bcTeam}
          <li>{participant.member && participant.member.name}</li>
        </ul>
      </div>
    );

    const participantNamesDropdown = (
      <div className="ParticipantPublicPage__Participant--dropdown">
        <HelpText>{t('participant-label')}</HelpText>
        <PublicTitleDropdown
          helpLabel={t('participants-competition-label')}
          title={participant.member.name}
          entities={this.sortParticipants(this.props.participants).filter(
            _participant => _participant.id !== participant.id
          )}
          getEntityLinkUrl={entity =>
            `/${locale}/${get(entity, 'campaignAlias')}/${get(
              entity,
              'teamAlias',
              t('individual-participant-url')
            )}/${get(entity, 'member.alias', '')}`
          }
          additionalLinks={
            <Link to={`/${locale}/${t('URL:participant-registration')}`}>
              <FontAwesomeIcon icon={['fal', 'arrow-right']} />
              {t('participate')}
            </Link>
          }
        />
      </div>
    );

    const isCurrentUserPage = userId === memberId ? true : false;

    const urlToShare = `${getPlatformUrl(platformAlias)}${location.pathname}`;

    const donationUrl = participant.teamAlias
      ? `/${locale}/${participant.campaignAlias}/${participant.teamAlias}/${get(
          participant,
          'member.alias',
          ''
        )}/${t('URL:give')}`
      : `/${locale}/${participant.campaignAlias}/${t('URL:individual-participant')}/${get(
          participant,
          'member.alias',
          ''
        )}/${t('URL:give')}`;

    const descritpionLanguageName = get(participant, 'description.language', null);
    const descriptionLanguageKey = getLanguageKeyByName(descritpionLanguageName || '');
    const isDescriptionLanguageInLocale = locale === descriptionLanguageKey;

    const showDescriptionLangNotice = descritpionLanguageName
      ? !isDescriptionLanguageInLocale
      : null;

    const descriptionText = get(participant, 'description.description', null);

    const description = descriptionText ? ReactHtmlParser(descriptionText) : null;

    const showNoDescriptionNotice = !description ? (
      <p className="info-notice-language">
        <FontAwesomeIcon icon={['fal', 'info-circle']} /> {t('notice-no-description')}
      </p>
    ) : null;

    const langInNotice = showDescriptionLangNotice
      ? descriptionLanguageKey !== 'en'
        ? `${t(`LANGUAGES:${descriptionLanguageKey}`)}.`
        : `${t(`LANGUAGES:${descriptionLanguageKey}`).toLowerCase()}.`
      : null;

    const noticeLang = showDescriptionLangNotice ? (
      <p className="info-notice-language">
        <FontAwesomeIcon icon={['fal', 'info-circle']} /> {t('notice-language')} {langInNotice}{' '}
      </p>
    ) : null;

    return (
      <Layout className="ParticipantPublicPage">
        <HeadMetas />
        <PublicCampaignBand
          campaign={campaign}
          lastCampaigns={this.props.lastCampaigns}
          locale={locale}
        />
        {dummyBreadcrumbs}
        <div className="ParticipantPublicPage__Container FundkyWrap">
          <div className="ParticipantPublicPage__Menu" id="titleDropdown">
            <PublicThermometer
              className="ParticipantPublicPage__Thermometer"
              locale={locale}
              entity={participant}
              additionalOutsideContent={participantNamesDropdown}
              giveUrl={{
                pathname: donationUrl
              }}
              isCurrentUserPage={isCurrentUserPage}
              participant
              donationClosed={isDonationsDisabled || isCampaignClosed}
              showThermometer={showThermometer}
              isBillingConfigured={this.props.isBillingConfigured}
            />
            <PublicPageClosed location="Thermo" campaign={campaign} />
            <div className="ParticipantPublicPage__SocialSharing globalDesktopOnly">
              <SocialSharing identity={'participant'} urlToShare={urlToShare} />
            </div>
          </div>

          <div className="ParticipantPublicPage__Content">
            <PublicPageMobileScripts
              donationURL={donationUrl}
              hideButtons={isCampaignClosed}
              donationDisabled={isDonationsDisabled}
              registrationDisabled={isRegistrationDisabled}
              isSinglePageCampaign={campaign.typeId === CAMPAIGN_TEMPLATE_SOLO}
              pageType="participant"
              entity={participant}
              locale={locale}
              socialShare={
                <SocialSharing identity={'participant'} urlToShare={urlToShare} showTitle={false} />
              }
              showThermometer={showThermometer}
              platform={platform}
              campaign={campaign}
              isBillingConfigured={this.props.isBillingConfigured}
            />

            <PublicPageClosed location="Banner" campaign={campaign} />

            <div className="globalMobileOnly">
              <PublicPageClosed location={'Thermo'} campaign={campaign} />
            </div>

            <div id="about">
              <PublicParticipantPanel
                locale={locale}
                title={t('about-me')}
                campaign={campaign}
                isCurrentUserPage={isCurrentUserPage}
                participant={participant}
                isClosed={isCampaignClosed || isRegistrationDisabled}
                platform={platform}
              >
                <React.Fragment>
                  {showNoDescriptionNotice}
                  {noticeLang}
                  {description ? (
                    typeof description === 'string' ? (
                      <p>{description}</p>
                    ) : (
                      description
                    )
                  ) : null}
                </React.Fragment>
              </PublicParticipantPanel>
            </div>
            <PublicParticipantTeamMembersPanel teamName={team.name} isClosed={isCampaignClosed} />
            <PublicDonationPanel type="participant" />
          </div>
        </div>
      </Layout>
    );
  }
}

ParticipantPublicPage.propTypes = {
  locale: PropTypes.oneOf(['fr', 'en']).isRequired
};

export default translate('ParticipantPublicPage')(ParticipantPublicPage);
