import React from 'react';
import { Layout, Tooltip } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';
import Can from 'Parts/session/components/CanContainer';
import CsvReportButton from '../../../report/components/CsvReportButtonContainer';

import campaignsParticipantsListPageEN from './locales/CampaignsParticipantsListPage_en.json';
import campaignsParticipantsListPageFR from './locales/CampaignsParticipantsListPage_fr.json';

class CampaignsParticipantsListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle(
      'en',
      'CampaignsParticipantsListPage',
      campaignsParticipantsListPageEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsParticipantsListPage',
      campaignsParticipantsListPageFR
    );
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchParticipantsInCampaign(
      this.props.campaignId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleViewHandler = participants => {
    let currentUrl = this.props.location.pathname;
    let participantUrl = currentUrl.replace('list', participants.id);
    return this.props.history.push(participantUrl);
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchParticipantsInCampaign(
      this.props.campaignId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  render() {
    const { t, match, participants, pagination, locale } = this.props;
    const { pager } = this.state;

    const CsvReportButtonDisabled = !participants || participants.length == 0;
    const tooltipVisibility = !CsvReportButtonDisabled ? { visible: false } : {};
    return (
      <Layout className="CampaignsParticipantsListPage">
        <Helmet>
          <title>{t('Participants')}</title>
        </Helmet>

        <SearchBar
          title={t('Participants')}
          placeholder={t('Search')}
          createButton={ <div>
            <CsvReportButton
              key={2}
              data={this.props.report}
              id={this.props.campaign.id}
              pagination={this.props.pagination}
              orderBy={{ orderBy: 'addedOn[desc]' }}
              filters={this.props.filters}
              fileName={`${this.props.platform.alias}.${this.props.campaign.alias}.participants`}
              fetch={this.props.detailedReportParticipantsInCampaign}
              disabled={CsvReportButtonDisabled ? true : null}
              label={t('CsvDetailedReport')}
            />
          </div>}
          onSearch={this.handleSearch}
          reportButton={
            <Tooltip title={t('no-participant')} {...tooltipVisibility}>
              <div>
                <CsvReportButton
                  key={1}
                  data={this.props.report}
                  id={this.props.campaign.id}
                  pagination={this.props.pagination}
                  orderBy={{ orderBy: 'addedOn[desc]' }}
                  filters={this.props.filters}
                  fileName={`${this.props.platform.alias}.${this.props.campaign.alias}.participants`}
                  fetch={this.props.reportParticipantsInCampaign}
                  disabled={CsvReportButtonDisabled ? true : null}
                />
              </div>
            </Tooltip>
          }
        />
        <Can
          rules={['permissions.campaign.participant.get']}
          campaignId={get(match, 'params.campaignID', null)}
          redirect={`/${locale}/d`}
        >
          <div className="AdminList">
            <PaginationTop
              totalLabel={total => t('participantsTotal', { count: pagination.totalResults })}
              pager={pager}
              onChange={this.handlePaginationChange}
            />
            <ResultTable
              dataSource={participants}
              pagination={pager}
              onChange={this.handlePaginationChange}
              showHeader={true}
              columns={[
                //simpleColumn(t('Participant ID'), 'id', null, true),
                simpleColumn(t('Full name'), 'member.name', null, true),
                simpleColumn(t('Email address'), 'member.email', null, true),
                dateColumn(t('Registered on'), 'addedOn', null, null, false, locale),
                actionColumn(t('Edit'), ['fal', 'edit'], this.handleViewHandler)
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

CampaignsParticipantsListPage.defaultProps = {
  participants: [],
  pagination: {
    totalResults: 0
  }
};

CampaignsParticipantsListPage.propTypes = {
  // organizations: PropTypes.shape({
  //   total: PropTypes.number.isRequired,
  //   rows: PropTypes.array.isRequired
  // }).isRequired,
  // history: PropTypes.object.isRequired,
};

export default translate('CampaignsParticipantsListPage')(CampaignsParticipantsListPage);
