import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { processTransaction, cancelTransaction } from "../donationActions";
import { surveyAttemptAction } from "./SurveyAttemptAction";
import { getDonationTarget } from "../donationSelectors";

import CreatePaymentForm from './CreatePaymentForm';

const mapStateToProps = state => ({
  tickets: state.ticketSelection,
  information: state.donation.donationInformation,
  target: getDonationTarget(state),
  platformId: state.platform.platform.id,
  platform: state.platform.platform,
  locale: state.session.language,
  browser: state.session.browser,
  isTransactionInProgress: state.ui.isTransactionInProgress,
  countries: state.geo.countries,
  billing: state.organization.organization.billing,
  surveySubmitData: state.surveyAttempt.surveySubmitData,
  surveyId: state.donation.surveyId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  processTransaction,
  cancelTransaction,
  surveyAttemptAction,
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  withGoogleReCaptcha,
)(CreatePaymentForm);
