import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { sumBy, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';
import PublicCarousel from '../../common/PublicCarousel';
import PublicPanel from '../../common/PublicPanel';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';
import HelpText from '../../common/HelpText';

import { CAMPAIGN_TEMPLATE_TEAMS } from '../campaignUtils';

import PublicTeamPanel_en from './locales/PublicTeamPanel_en.json';
import PublicTeamPanel_fr from './locales/PublicTeamPanel_fr.json';

import './PublicTeamPanel.less';

class PublicTeamPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicTeamPanel', PublicTeamPanel_en);
    i18next.addResourceBundle('fr', 'PublicTeamPanel', PublicTeamPanel_fr);
  }

  getSoloParticipants = (participants, campaignAlias) => {
    if (!participants) {
      return null;
    }

    const { t, locale } = this.props;

    const sortedParticipants = participants.sort((a, b) =>
      Number(get(b, 'collected', 0).toString().replace('NaN', 0)) - Number(get(a, 'collected', 0).toString().replace('NaN', 0)) ||
      a.member.firstName.toLowerCase().localeCompare(b.member.firstName.toLowerCase()) ||
      a.member.lastName.toLowerCase().localeCompare(b.member.lastName.toLowerCase())
    );

    const participantBlocks = sortedParticipants.map(participant => {
      return (
        <Link
          to={`/${locale}/${participant.campaignAlias}/${t('URL:individual-participant')}/${
            participant.member.alias
          }`}
          key={participant.id}
        >
          <AvatarThumbnail
            className="PublicTeamPanel__Participant"
            key={participant.id}
            entity={participant}
            primaryContent={<span>{participant.member.name}</span>}
            secondaryContent={moneyFormat(Number(get(participant, 'collected', 0).toString().replace('NaN', 0)), locale)}
          />
        </Link>
      );
    });

    return (
      <div className="PublicTeamPanel__Team" key={0}>
        <div className="PublicTeamPanel__Divider">
          <div className="PublicTeamPanel__Information">
            <div className="PublicTeamPanel__Name">
              <span>{t('individual-participants')}</span>
            </div>
            <span className="PublicTeamPanel__Collected">
              {moneyFormat(
                sumBy(participants, p => Number(get(p, 'collected', 0).toString().replace('NaN', 0))),
                locale
              )}
            </span>
          </div>
        </div>
        <PublicCarousel
          className="PublicTeamPanel__Carousel"
          slidesToShow={this.props.slidesToShow}
          sideControls={participantBlocks.length > this.props.slidesToShow ? true : false}
          initialSlideHeight={
            64 /* TODO define the height + the padding in JS, and inject these variables in LESS files */
          }
          heightMode="max"
          delayInit
        >
          {participantBlocks}
        </PublicCarousel>
      </div>
    );
  };

  render() {
    const { props } = this,
      { t, locale, campaign, teams, participants, slidesToShow, isClosed, platform } = props;

    let limit = 0;
    let count = 0;

    if (
      (campaign.typeId === 2 || campaign.typeId === 3) &&
      get(campaign, 'settings.team.limit', 0) > 0 &&
      get(campaign, 'settings.participant.limit', 0) > 0
    ) {
      limit = Number(campaign.settings.team.limit) * Number(campaign.settings.participant.limit);
    }

    const sortedTeams = teams.sort((a, b) =>
      Number(get(b, 'collected', 0).toString().replace('NaN', 0)) - Number(get(a, 'collected', 0).toString().replace('NaN', 0)) ||
      a.name.toLowerCase().localeCompare(a.name.toLowerCase())
    );

    const teamBlocks = sortedTeams.map((team, i) => {
      const winnerIcon = i < 3
        ? i === 0
          ? <FontAwesomeIcon icon={['fas', 'trophy-alt']} />
          : <FontAwesomeIcon icon={['fas', 'trophy']} />
        : null;

      const sortedParticipants = (participants[team.id] || []).sort((a, b) =>
        Number(get(b, 'collected', 0).toString().replace('NaN', 0)) - Number(get(a, 'collected', 0).toString().replace('NaN', 0)) ||
        a.member.firstName.toLowerCase().localeCompare(b.member.firstName.toLowerCase()) ||
        a.member.lastName.toLowerCase().localeCompare(b.member.lastName.toLowerCase())
      );

      const participantBlocks = sortedParticipants.map(participant => {
        const captainIcon = team.leaderId === participant.id
          ? <FontAwesomeIcon icon={['fas', 'star']} />
          : null;

        count++;

        return (
          <Link
            to={`/${locale}/${participant.campaignAlias}/${participant.teamAlias}/${participant.member.alias}`}
            key={participant.id}
          >
            <AvatarThumbnail
              className="PublicTeamPanel__Participant"
              key={participant.id}
              entity={participant}
              primaryContent={
                <span>
                  {participant.member.name} {captainIcon}
                </span>
              }
              secondaryContent={moneyFormat(Number(get(participant, 'collected', 0).toString().replace('NaN', 0)), locale)}
            />
          </Link>
        );
      });

      return (
        <div className="PublicTeamPanel__Team" key={team.id}>
          <Link
            className="PublicTeamPanel__Divider"
            to={`/${locale}/${campaign.alias}/${team.alias}`}
          >
            <div className="PublicTeamPanel__Information">
              {winnerIcon}
              <span className="PublicTeamPanel__Position">{i + 1}</span>
              <div className="PublicTeamPanel__Name">
                <HelpText>{t('team-label')}</HelpText>
                <span>{team.name}</span>
              </div>
              <span className="PublicTeamPanel__Collected">
                {moneyFormat(Number(get(team, 'collected', 0).toString().replace('NaN', 0)), locale)}
              </span>
            </div>
            <FontAwesomeIcon icon={['fal', 'arrow-right']} />
          </Link>
          <PublicCarousel
            className="PublicTeamPanel__Carousel"
            slidesToShow={slidesToShow}
            sideControls={participantBlocks.length > slidesToShow ? true : false}
            initialSlideHeight={
              64 /* TODO define the height + the padding in JS, and inject these variables in LESS files */
            }
            heightMode="max"
            delayInit
          >
            {participantBlocks}
          </PublicCarousel>
        </div>
      );
    });

    const soloBlock = campaign.typeId !== CAMPAIGN_TEMPLATE_TEAMS ? this.getSoloParticipants(participants.null, campaign.alias) : null;

    const noParticipantMessage = teamBlocks.length <= 0 && !soloBlock
      ? <span>{t('first-participant')}</span>
      : null;

    const registrationDisabled = get(campaign, 'settings.registration.disabledOn')
      ? isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
      : false;

    return (
      <PublicPanel
        className="PublicTeamPanel"
        title={t('title')}
        id="participants"
        searchable
        searchPlaceholder={t('search')}
        footer={!isClosed && !registrationDisabled}
        count={count}
        limit={limit}
        unit="teams"
        full="campaign"
        campaign={campaign}
        platform={platform}
      >
        {teamBlocks}
        {soloBlock}
        {noParticipantMessage}
      </PublicPanel>
    );
  }
}

PublicTeamPanel.propTypes = {
  campaign: PropTypes.shape({
    alias: PropTypes.string.isRequired
  }).isRequired,
  teams: PropTypes.array.isRequired,
  participants: PropTypes.object.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  isClosed: PropTypes.bool.isRequired
};

export default translate('PublicTeamPanel')(PublicTeamPanel);
