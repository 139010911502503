import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import CsvReportButton_en from './locales/CsvReportButton_en.json';
import CsvReportButton_fr from './locales/CsvReportButton_fr.json';

class CsvReportButton extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CsvReportButton', CsvReportButton_en);
    i18next.addResourceBundle('fr', 'CsvReportButton', CsvReportButton_fr);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const { t, clearReport } = this.props;
    //Fetch CSV from API as blob
    this.props.fetch(this.props.id, this.props.filters, this.props.pagination, this.props.orderBy, this.props.authentication)
      //Create URL and File Name
      .then(() => {
        //Avoid server error with typeof window
        const href = typeof window && window.URL.createObjectURL(this.props.data) || "#";
        const fileName = `${t('Report')}.${this.props.fileName}.csv`;
        this.download(href, fileName);
      })
      //Clear CSV
      .then(() => clearReport())
      .catch(error => console.warn(error));
  }

  download(url, fileName) {
    //Create element <a> to trigger CSV download
    var element = document.createElement('a');
    //Set Attributes
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.setAttribute('rel', 'noopener noreferrer');
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    //Append element
    document.body.appendChild(element);
    //Trigger click
    element.click();
    //Remove element
    document.body.removeChild(element);
  }

  render() {
    const { t } = this.props;

    return (
      <a
        className="CsvReportButton ant-btn"
        onClick={this.handleClick}
        disabled={this.props.disabled || null}
      >
        <span>{`${this.props.label !== null ? this.props.label : t('CSV')} `}</span>
        <FontAwesomeIcon icon={["fal", "file-download"]} />
      </a>
    );
  }
}

CsvReportButton.defaultProps = {
  id: null,
  filters: {},
  pagination: {},
  orderBy: {orderBy:'addedOn[desc]'},
  authentication: false,
  fileName: "",
  fetch: null,
  clearReport: null,
  disabled: null,
  label: null,
};

CsvReportButton.propTypes = {
  id: PropTypes.number.isRequired,
  filters: PropTypes.object,
  pagination: PropTypes.object,
  orderBy: PropTypes.object,
  authentication: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  clearReport: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default translate('CsvReportButton')(CsvReportButton);
