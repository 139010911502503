import React from 'react';
import { Form, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

//import TypeField from './fields/TypeField';
import DonationTypeField from 'Parts/ui/components/fields/DonationTypeField';
import AmountField from './fields/AmountField';
import PPFField from './fields/PPFField';
import TextAreaField from 'Parts/ui/components/fields/TextAreaField';
import AnonymousField from 'Parts/ui/components/fields/AnonymousField';
import SubmitButton from 'Parts/common/SubmitButton';
import FundkyTooltip from 'Parts/ui/components/FundkyTooltip';
import MonthlyField from './fields/MonthlyField';

import {
  DONATION_CREATE_WIZARD_INFORMATION,
  DONATION_CREATE_WIZARD_CONFIRMATION,
  DONATION_CREATE_SURVEY
} from '../../ui/uiUtils';

import CreateDonationForm_en from './locales/CreateDonationForm_en.json';
import CreateDonationForm_fr from './locales/CreateDonationForm_fr.json';

import './CreateDonationForm.less';
import { getTotalAmount } from '../../common/donationAmount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals } from '@fortawesome/pro-light-svg-icons'

class CreateDonationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      monthly: props.form.getFieldValue('frequency') === 'monthly',
      donationType: props.form.getFieldValue('type')
    };

    this.showMonthly = get(props, 'platform.actions.canReceiveSubscriptions') && props.history.location.pathname.includes(`/${props.locale}/${props.t('URL:organization/give')}`);

    i18next.addResourceBundle('en', 'CreateDonationForm', CreateDonationForm_en);
    i18next.addResourceBundle('fr', 'CreateDonationForm', CreateDonationForm_fr);
  }

  handleSubmit = e => {
    e.preventDefault();
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, index) {
          if (values[key] === null) {
            delete values[key];
          }
        });
        
        values.amount = getTotalAmount();
        this.props.storeDonationInformation(values);
        this.props.handleTabChange(DONATION_CREATE_WIZARD_INFORMATION);
      }
    });
  };

  handleCancelClick = e => {
    e.preventDefault();
    this.props.cancelTransaction();
    this.props.handleTabChange(DONATION_CREATE_SURVEY, true);
  };

  handleMonthly = e => {
    this.setState({
      monthly: e.target.value === 'monthly'
    });
  };

  handleDonationType = e => {
    this.setState({
      donationType: e.target.value
    });
  };

  render() {
    const {
      t,
      locale,
      infos,
      platform,
      selectedCampaign,
      selectedTeam,
      selectedParticipant,
      campaign
    } = this.props;
    const type = infos && infos.type ? infos.type : null;
    const amount = infos && infos.amount ? infos.amount : null;
    const message = infos && infos.message ? infos.message : null;
    const anonymous = infos && infos.anonymous ? infos.anonymous : null;
    // plugins savedValue
    const taxReceipt = infos && infos.taxReceipt ? infos.taxReceipt : null;

    let custom = true;
    const entity = selectedParticipant || selectedTeam || selectedCampaign || null;

    let ppf = true;

    if (
      get(platform, 'settings.donation.preset.custom') === "0" ||
      get(campaign, 'settings.donation.preset.custom') === "0" &&
      entity
    ) {
      custom = false;
    }

    const presets =
      get(selectedCampaign, 'settings.donation.preset', null) ||
      get(selectedTeam, 'settings.donation.preset', null) ||
      get(selectedParticipant, 'settings.donation.preset', null) ||
      get(campaign, 'settings.donation.preset', null) ||
      get(platform, 'settings.donation.preset', null);

    const amounts = presets ? [presets.A, presets.B, presets.C, presets.D] : [5, 10, 20, 50];

    const donationTypeTooltip = (
      <Markdown>
        {t('personal-desc')}
        {t('corporate-desc')}
        {t('regroup-desc')}
      </Markdown>
    );

    return (
      <div className="FundkyWrap">
        <Form className="CreateDonationForm" onSubmit={this.handleSubmit}>
          <h2>{t('title')}</h2>
          <p id="is">
            {t('is')}{' '}
            <FundkyTooltip
              title={donationTypeTooltip}
              popupContainer={() => document.getElementById('is')}
            />
          </p>
          <DonationTypeField
            className="CreateDonationForm__Field"
            form={this.props.form}
            initialValue={type}
            platform={platform}
            pluginSavedValue={taxReceipt}
            label={false}
            placholder={false}
            plugin={true}
            organization={this.props.organization}
            monthly={this.state.monthly || false}
            onChange={this.handleDonationType}
          />
          <p>{t('how-much')}</p>
          <AmountField
            id="amount"
            className="CreateDonationForm__Field CreateDonationForm__Field--NoFeedbackIcon"
            locale={locale}
            form={this.props.form}
            savedValue={amount}
            amounts={amounts}
            custom={custom}
          />
          <PPFField
            className="CreateDonationForm__Field"
            locale={locale}
            form={this.props.form}
          />
          <div className="donationRow">
            <div className="rowContent">
              <span>{t('accepted_credit_cards')} : Visa, Mastercard, American Express, Discover</span>
            </div>
          </div>
          {this.showMonthly &&
          <MonthlyField
            className="CreateDonationForm__Field"
            locale={locale}
            form={this.props.form}
            savedValue={null}
            onChange={this.handleMonthly}
            platform={this.props.platform}
            organization={this.props.organization}
            donationType={this.state.donationType}
          />
          }
          <TextAreaField
            className="CreateDonationForm__Field"
            form={this.props.form}
            maxlength={250}
            initialValue={message}
            hasFeedback={true}
          />
          <AnonymousField
            className="CreateDonationForm__Field"
            form={this.props.form}
            fieldFormat="checkbox"
            label={false}
            placholder={false}
            initialValue={anonymous}
          />
          <div className="CreateDonationForm__SubmitWrapper">
            <SubmitButton>{t('next')}</SubmitButton>
          </div>
          <div className="CreateDonationForm__CancelWrapper">
            <Popconfirm
              placement="topLeft"
              getPopupContainer={() => document.getElementById('popContainer')}
              title={t('DonationPage:cancelText')}
              onConfirm={this.handleCancelClick}
              okText={t('DonationPage:yes')}
              cancelText={t('DonationPage:no')}
            >
              <span id="popContainer">{t('cancel')}</span>
            </Popconfirm>
          </div>
        </Form>
      </div>
    );
  }
}

CreateDonationForm.propTypes = {
  form: PropTypes.object.isRequired,
  storeDonationInformation: PropTypes.func.isRequired
};

export default translate('CreateDonationForm')(CreateDonationForm);
