import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Form, Button, Popconfirm, notification } from 'antd';
import { get } from 'lodash';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const config = require(`../../config/config.${ENVIRONMENT}.json`);

import SubmitButton from 'Parts/common/SubmitButton';
import CardNameField from '../components/fields/CardNameField';
import PPFField from '../components/fields/PPFField';
import CardNumberField from '../components/fields/CardNumberField';
import CardExpirationField from '../components/fields/CardExpirationField';
import CardCvvField from './fields/CardCvvField';
import PostalCodeField from 'Parts/ui/components/fields/PostalCodeField';
import CountryField from 'Parts/ui/components/fields/CountryFieldContainer';
import {
  DONATION_CREATE_WIZARD_INFORMATION,
  DONATION_CREATE_WIZARD_CONFIRMATION
} from '../../ui/uiUtils';
import WinkNotification from '../../common/WinkNotification';

import '../../assets/images/wink@2x.png';

import './CreatePaymentForm.less';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    // [TEST TRANSACTION]
    // - For mashing submit button
    this.exceptions = [
      //{environment: "development", organizationId: 40, platformId: 66, campaignId: 405},
      //{environment: "preproduction", organizationId: 26, platformId: 27, campaignId: 516},//association-marianne/test-28721
    ];

    // Target of the exception
    this.target = get(this, 'props.target.targetType') === 'organisation'
      ? this.props.platform
      : get(this, 'props.target.entity') || get(this, 'props.target');

    this.state = {
      country: null,
      disabled: false,
      isException: false //this.checkExceptions(this.exceptions, this.target)
    };

    this.monthly = true;

    this.token = null;
  }

  componentDidMount() {
    this.setState({
      isException: false //this.checkExceptions(this.exceptions, this.target)
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isTransactionInProgress && this.props.isTransactionInProgress === null) {
    this.setState({
        disabled: false
      });
    }
  }

  handleSubmit = async (event) => {
    var _self = this;
    event.preventDefault();
    const { props, getReCaptchaToken } = this;
    const { t, information, target, stripe, elements, processTransaction, surveyAttemptAction, surveySubmitData, locale,surveyAttemptSubmitAction, surveyAttemptStoreAction} = props;
    if (!elements || !stripe) {
      return;
    }

    event.preventDefault();

    const cardElement = elements.getElement(CardElement);

    const stripeTools = { stripe, cardElement };

    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {

        this.setState({
          disabled: true
        });


        // Set reCAPTCHA token
        getReCaptchaToken('donation').then(()=>{
          const _information = {
            ...information,
            reCAPTCHA: this.token
          };

          processTransaction(_information, values, target, stripeTools)
            .then(response => {
              if (get(response, 'error.code', null)) {
                this.setState({
                  disabled: false
                });
                notification.error({ message: t(`ERROR:${get(response, 'error.code')}`) });
              } else if (this.props.surveyId) {
                surveyAttemptAction({surveySubmitData, transaction_id:response.payload.transaction.id}, locale);
              }
            });
        });

      }
    });
  };

  getReCaptchaToken = async action => {
    return this.token = await this.props.googleReCaptchaProps.executeRecaptcha(action);
  };

  checkExceptions = (exceptions, entity) => {
    let environment = ENVIRONMENT;
    let organizationId = entity.organization.id;
    let platformId = entity.platform && entity.platform.id || entity.id;
    let campaignId = entity.platform && !entity.campaign && entity.id ||
      entity.platform && entity.campaign && entity.campaign.id ||
      undefined;

    return exceptions.filter(exc => {
        return exc.environment === environment &&
            exc.organizationId === organizationId &&
            exc.platformId === platformId &&
            exc.campaignId === campaignId;
    }).length ? true : false;
  };

  render() {
    const {stripe, t, form, isTransactionInProgress, locale, ppf} = this.props;
    const infoDesc = (
        <>
          {t('enter-cc-detail')}
          <br />
          <br />
          <span className="WinkTooltip__Cc WinkTooltip__CTA">
            <FontAwesomeIcon className="cc-visa" icon={['fab', 'cc-visa']} />
            <FontAwesomeIcon className="cc-mastercard" icon={['fab', 'cc-mastercard']} />
            <FontAwesomeIcon className="cc-amex" icon={['fab', 'cc-amex']} />
            <FontAwesomeIcon className="cc-discover" icon={['fab', 'cc-discover']} />
          </span>
        </>
      );

    return (
        <Form className="CreatePaymentForm" onSubmit={this.handleSubmit}>
          <div className="CreatePaymentForm__Form">
            <h2>{t('title')}</h2>
            {ppf && <PPFField
              className="CreateDonationForm__Field"
              locale={locale}
              form={form}
            /> || null}
            <WinkNotification title={t('acceptedCards')} description={infoDesc} closable={false} />
            <p>{t('sub-title')}</p>
            <div className="CreatePaymentForm__Divider">
              <CardNameField
                className="CreatePaymentForm__Field CreatePaymentForm__Field--full"
                form={form}
              />
            </div>
            <CardElement
                options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: 'rgba(0,0,0,.85)',
                            backgroundColor: '#fff',
                            '::placeholder': {
                                color: '#d1d1d1',
                            },
                        },
                        invalid: {
                            color: '#f3704a',
                        },
                    },
                }}
            />
            <div className="CreatePaymentForm__Divider ZipCodeCountry">
              <CountryField
                className="CreatePaymentForm__Field CreatePaymentForm__Field--full"
                form={form}
                label={false}
                placeholder={true}
                onChangeHandler={this.onChangeHandler}
                initialValue={get(this.props, 'information.country') || get(this.props, 'information.transaction.information.country') || undefined}
              />
              {/* <PostalCodeField
                className="CreatePaymentForm__Field CreatePaymentForm__Field--half"
                form={form}
                placeholder={true}
                requiredMessage={t('postalCodeRequire')}
                country={this.state.country}
                initialValue={get(this.props, 'information.postalCode') || get(this.props, 'information.transaction.information.postalCode') || undefined}
              /> */}
            </div>
            <div className="CreatePaymentForm__SubmitWrapper">
              <Button className="Back-btn" onClick={this.props.handleBackClick} disabled={isTransactionInProgress ? true : false}>
                {t('back')}
              </Button>
              <SubmitButton
                loading={!this.state.isException ? isTransactionInProgress !== null : false}
                dataLayer={this.dataLayer}
                disabled={!this.state.isException ? this.state.disabled || !stripe  : false}
              >
                {isTransactionInProgress !== null &&
                  <CircularProgressbar value={isTransactionInProgress} />
                }{t('pay')}
              </SubmitButton>
            </div>
            <div className="CreatePaymentForm__CancelWrapper">
              <Popconfirm
              	placement="topLeft"
              	getPopupContainer={() => document.getElementById('popContainer3')}
              	title={t('TRANSACTION:cancelText')}
              	onConfirm={this.props.handleCancelClick}
              	okText={t('TRANSACTION:yes')}
              	cancelText={t('TRANSACTION:no')}
              >
                <span id="popContainer3">
                  {t('cancel')}
                </span>
              </Popconfirm>
            </div>
          </div>
        </Form>
    );
  }
}

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        t={props.t}
        ppf={props.ppf}
        isTransactionInProgress={props.isTransactionInProgress}
        form={props.form}
        information={props.information}
        target={props.target}
        countries={props.countries}
        processTransaction={props.processTransaction}
        handleBackClick={props.handleBackClick}
        handleCancelClick={props.handleCancelClick}
        googleReCaptchaProps={props.googleReCaptchaProps}
        platform={props.platform}
        surveyAttemptAction={props.surveyAttemptAction}
        surveySubmitData={props.surveySubmitData}
        locale={props.locale}
        surveyId={props.surveyId}
        surveyAttemptSubmitAction={props.surveyAttemptSubmitAction}
        surveyAttemptStoreAction={props.surveyAttemptStoreAction}
        />
    )}
  </ElementsConsumer>
);

const StripePaymentForm = (props) => {
  const stripePromise = loadStripe(config.stripe, {stripeAccount: props.stripeAccount});
  return (
    <Elements stripe={stripePromise} options={{locale: props.locale}}>
      <InjectedCheckoutForm
        t={props.t}
        ppf={props.ppf}
        isTransactionInProgress={props.isTransactionInProgress}
        form={props.form}
        information={props.information}
        target={props.target}
        countries={props.countries}
        processTransaction={props.processTransaction}
        handleBackClick={props.handleBackClick}
        handleCancelClick={props.handleCancelClick}
        googleReCaptchaProps={props.googleReCaptchaProps}
        platform={props.platform}
        surveyAttemptAction={props.surveyAttemptAction}
        surveySubmitData={props.surveySubmitData}
        locale={props.locale}
        surveyId={props.surveyId}
        surveyAttemptSubmitAction={props.surveyAttemptSubmitAction}
        surveyAttemptStoreAction={ props.surveyAttemptStoreAction}
      />
    </Elements>
  );
};

export default translate('CreatePaymentForm')(StripePaymentForm);
