import creditCardType from 'credit-card-type';
import {
  notification
} from 'antd';
import i18next from 'i18next';
import {
  get
} from 'lodash';
import {loadStripe} from '@stripe/stripe-js';

import {
  requestApiGet,
  requestApiPost,
  requestApiPut
} from 'Parts/common/requestUtils';
import { getSessionIp } from 'Parts/session/sessionUtils';
import { updateSessionState } from "Parts/session/sessionActions";
import { getWepayKey } from "Parts/common/environment";
import { moneyToNumber } from 'Parts/common/moneyFormat';
import { faGameConsoleHandheld } from '@fortawesome/pro-light-svg-icons';

export const UI_DONATION_ORGANIZATION_SELECT = 'UI_DONATION_ORGANIZATION_SELECT';
export const UI_DONATION_CAMPAIGN_SELECT = 'UI_DONATION_CAMPAIGN_SELECT';
export const UI_DONATION_TEAM_SELECT = 'UI_DONATION_TEAM_SELECT';
export const UI_DONATION_PARTICIPANT_SELECT = 'UI_DONATION_PARTICIPANT_SELECT';
export const UI_DONATION_CLEAR = 'UI_DONATION_CLEAR';

export const UI_STORE_DONATION_INFORMATION = 'UI_STORE_DONATION_INFORMATION';
export const UI_STORE_USER_INFORMATION = 'UI_STORE_USER_INFORMATION';

export const FETCH_CREDIT_CARD_ID_START = 'FETCH_CREDIT_CARD_ID_START';
export const FETCH_CREDIT_CARD_ID_SUCCESS = 'FETCH_CREDIT_CARD_ID_SUCCESS';
export const FETCH_CREDIT_CARD_ID_ERROR = 'FETCH_CREDIT_CARD_ID_ERROR';

export const FETCH_CREDIT_CARD_ID_STRIPE_START = 'FETCH_CREDIT_CARD_ID_STRIPE_START';
export const FETCH_CREDIT_CARD_ID_STRIPE_SUCCESS = 'FETCH_CREDIT_CARD_ID_STRIPE_SUCCESS';
export const FETCH_CREDIT_CARD_ID_STRIPE_ERROR = 'FETCH_CREDIT_CARD_ID_STRIPE_ERROR';

export const FETCH_DONATION_LIST_BY_PLATFORM_START = 'FETCH_DONATION_LIST_BY_PLATFORM_START';
export const FETCH_DONATION_LIST_BY_PLATFORM_SUCCESS = 'FETCH_DONATION_LIST_BY_PLATFORM_SUCCESS';
export const FETCH_DONATION_LIST_BY_PLATFORM_ERROR = 'FETCH_DONATION_LIST_BY_PLATFORM_ERROR';

export const FETCH_DONATION_LIST_BY_CAMPAIGN_START = 'FETCH_DONATION_LIST_BY_CAMPAIGN_START';
export const FETCH_DONATION_LIST_BY_CAMPAIGN_SUCCESS = 'FETCH_DONATION_LIST_BY_CAMPAIGN_SUCCESS';
export const FETCH_DONATION_LIST_BY_CAMPAIGN_ERROR = 'FETCH_DONATION_LIST_BY_CAMPAIGN_ERROR';

export const FETCH_DONATION_LIST_BY_TEAM_START = 'FETCH_DONATION_LIST_BY_TEAM_START';
export const FETCH_DONATION_LIST_BY_TEAM_SUCCESS = 'FETCH_DONATION_LIST_BY_TEAM_SUCCESS';
export const FETCH_DONATION_LIST_BY_TEAM_ERROR = 'FETCH_DONATION_LIST_BY_TEAM_ERROR';

export const FETCH_DONATION_LIST_BY_PARTICIPANT_START = 'FETCH_DONATION_LIST_BY_PARTICIPANT_START';
export const FETCH_DONATION_LIST_BY_PARTICIPANT_SUCCESS = 'FETCH_DONATION_LIST_BY_PARTICIPANT_SUCCESS';
export const FETCH_DONATION_LIST_BY_PARTICIPANT_ERROR = 'FETCH_DONATION_LIST_BY_PARTICIPANT_ERROR';

export const FETCH_TRANSACTION_BY_ID_START = 'FETCH_TRANSACTION_BY_ID_START';
export const FETCH_TRANSACTION_BY_ID_SUCCESS = 'FETCH_TRANSACTION_BY_ID_SUCCESS';
export const FETCH_TRANSACTION_BY_ID_ERROR = 'FETCH_TRANSACTION_BY_ID_ERROR';

export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';

export const FETCH_TRANSACTION_LOGS_START = 'FETCH_TRANSACTION_LOGS_START';
export const FETCH_TRANSACTION_LOGS_SUCCESS = 'FETCH_TRANSACTION_LOGS_SUCCESS';
export const FETCH_TRANSACTION_LOGS_ERROR = 'FETCH_TRANSACTION_LOGS_ERROR';

export const UPDATE_TRANSACTION_START = 'UPDATE_TRANSACTION_START';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_ERROR = 'UPDATE_TRANSACTION_ERROR';

export const SAVE_DONATION_CREATE_START = 'SAVE_DONATION_CREATE_START';

export const SAVE_DONATION_CREATE_OFFLINE_START = 'SAVE_DONATION_CREATE_OFFLINE_START';
export const SAVE_DONATION_CREATE_OFFLINE_SUCCESS = 'SAVE_DONATION_CREATE_OFFLINE_SUCCESS';
export const SAVE_DONATION_CREATE_OFFLINE_ERROR = 'SAVE_DONATION_CREATE_OFFLINE_ERROR';

export const FETCH_SURVEBY_PLATFORMBYID_START = 'FETCH_SURVEBY_PLATFORMBYID_START';
export const FETCH_SURVEBY_PLATFORMBYID_SUCCESS = 'FETCH_SURVEBY_PLATFORMBYID_SUCCESS';
export const FETCH_SURVEBY_PLATFORMBYID_ERROR = 'FETCH_SURVEBY_PLATFORMBYID_ERROR';

export const SAVE_DONATION_CHECKOUT_START = 'SAVE_DONATION_CHECKOUT_START';
export const SAVE_DONATION_CHECKOUT_SUCCESS = 'SAVE_DONATION_CHECKOUT_SUCCESS';
export const SAVE_DONATION_CHECKOUT_DECLINED = 'SAVE_DONATION_CHECKOUT_DECLINED';
export const SAVE_DONATION_CHECKOUT_ERROR = 'SAVE_DONATION_CHECKOUT_ERROR';

export const CANCEL_DONATION = 'CANCEL_DONATION';

export function processTransaction(donationInformation, cardInformation, donationTarget, stripeTools = null) {
  return dispatch => {
    if (/*donationInformation.frequency === "monthly" &&*/ stripeTools) {
      return stripeTools.stripe.createToken(stripeTools.cardElement)
        .then(function(result) {
          // Handle result.error or result.token
          if (!result.error) {
            if(donationInformation.frequency === "monthly") {
              return dispatch(
                checkoutSubscription(
                  result.token.id,
                  //payload.credit_card_type,
                  donationInformation,
                  donationTarget
                )
              );
            } else {
              return dispatch(
                checkoutDonation(
                  result.token.id,
                  //payload.credit_card_type,
                  donationInformation,
                  donationTarget,
                  'stripe'
                )
              );
            }

          } else {
            return result;
          }

        });
    } else {
      return dispatch(createCreditCard(cardInformation, donationInformation.email)).then(
        ({
          payload,
          error
        }) => {
          if (!error) {
            return dispatch(
              checkoutDonation(
                payload.credit_card_id,
                //payload.credit_card_type,
                donationInformation,
                donationTarget,
                'wepay'
              )
            );
          }
        }
      );
    }
  };
}

// WEPAY --------------------------------------------------------
export function createCreditCard(cardInformation, email) {
  return dispatch => {
    // 1. Dispatch the start event
    dispatch({
      type: FETCH_CREDIT_CARD_ID_START
    });

    // 2. Set WePay endpoint
    if (ENVIRONMENT === 'production') {
      WePay.set_endpoint('production');
    } else {
      WePay.set_endpoint('stage');
    }

    // 3. Build the object expected by the WePay credit_card/create endpoint
    // See https://developer.wepay.com/api/api-calls/credit_card#create
    const creditCardInfo = creditCardType(cardInformation.cardNumber);
    const client_id = getWepayKey();

    const info = {
      client_id: client_id,
      cc_number: cardInformation.cardNumber,
      expiration_month: cardInformation.cardExpirationMonth,
      expiration_year: `20${cardInformation.cardExpirationYear}`,
      cvv: cardInformation.cardCvv,
      user_name: cardInformation.cardName,
      email: email,
      address: {
        country: cardInformation.country,
        postal_code: cardInformation.postalCode
      }
    };

    // 4. Promisify the good old callback method
    const promise = new Promise((resolve, reject) => {
      const response = WePay.credit_card.create(info, function (data) {
        if (data.error) {
          console.error(data.error);
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:invalid-request'),
            description: data.error_description
          });
          reject(data.error);
        } else {
          resolve(data);
        }
      });

      if (response !== true) {
        reject(`Bad WePay API response: ${response}`);
      }
    });

    // 5. Call the promise
    return promise
      .then(result =>
        dispatch({
          type: FETCH_CREDIT_CARD_ID_SUCCESS,
          payload: {
            ...result,
            credit_card_type: creditCardInfo[0].type,
            credit_card_last_four: cardInformation.cardNumber.slice(-4)
          }
        })
      )
      .catch(error => {
        console.error(error);
        return dispatch({
          type: FETCH_CREDIT_CARD_ID_ERROR,
          error
        });
      });
  };
}
// END ----------------------------------------------------------

export function getCheckoutTarget(target) {
  switch (target.targetType) {
    case 'organisation':
      return { target: {
        platformId: target.targetId
      }};
      break;
    case 'campaign':
      return { target: {
        campaignId: target.targetId
      }};
      break;
    case 'team':
      return { target: {
        teamId: target.targetId
      }};
      break;
    case 'participant':
      return { target: {
        participantId: target.targetId
      }};
      break;
    default:
      return null;
      break;
  }
}

export function checkoutDonation(
  creditCardId,
  //creditCardType,
  donationInformation,
  donationTarget,
  gateway = null
) {
  return dispatch => {
    dispatch({
      type: SAVE_DONATION_CHECKOUT_START,
      // payload: {
      //   creditCardId,
      //   creditCardType
      // }
    });

    const organization =
      parseInt(donationInformation.type) == 2 ?
      {
        organizationName: donationInformation.organizationName,
        organizationTitle: donationInformation.organizationTitle
      } :
      null;

    const plugins = donationInformation.taxReceipt ?
      {
        taxReceipt: true
      } :
      undefined;

    const taxReceiptInfo = true /*plugins*/ ?
      {
        address: donationInformation.address || undefined,
        unit: donationInformation.unit && donationInformation.unit.length > 0 ?
          donationInformation.unit :
          undefined,
        city: donationInformation.city || undefined,
        province: donationInformation.province || undefined
      } :
      null;

    const body = {
      reCAPTCHA: donationInformation.reCAPTCHA || null,
      donationTypeId: parseInt(donationInformation.type),
      message: donationInformation.message && donationInformation.message.trim() !== '' && donationInformation.message.trim() || undefined,
      anonymous: donationInformation.anonymous || false,
      payment: {
        ...(gateway === 'wepay') && {wepay: {creditCardId: creditCardId}} ||
           (gateway === 'stripe') && {stripe: {cardTokenId: creditCardId}} ||
           {}
      },
      //creditCardId: creditCardId,
      ...getCheckoutTarget(donationTarget),
      newsletter: {
        platform: donationInformation.hasOwnProperty('bulletinOptin')
          ? donationInformation.bulletinOptin
            ? donationInformation.bulletinOptin
            : false
          : undefined,
        languageId: parseInt(donationInformation.locale)
      },
      transaction: {
        amount: moneyToNumber(donationInformation.amount),
        information: {
          ...organization,
          honorificId: parseInt(donationInformation.gender),
          firstName: donationInformation.firstname,
          lastName: donationInformation.lastname,
          email: donationInformation.email,
          country: donationInformation.country,
          ...taxReceiptInfo,
          postalCode: donationInformation.postalCode,
        }
      },
      //saveInformation: donationInformation.saveInformation || undefined,
      plugins
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      `/donations`,
      body,
      headers
    )
      .then(donation => {
        if (donation.checkout.transactionStatus === 'APPROVED') {
          return dispatch({
            type: SAVE_DONATION_CHECKOUT_SUCCESS,
            payload: donation
          });
        } else if (body.saveInformation) {
          return dispatch(updateSessionState());
        } else {
          return dispatch({
            type: SAVE_DONATION_CHECKOUT_DECLINED,
            payload: donation
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: SAVE_DONATION_CHECKOUT_ERROR,
          error
        });
      });
  };
}

export function checkoutSubscription(
  cardTokenId,
  //creditCardType,
  donationInformation,
  donationTarget
) {
  return dispatch => {
    dispatch({
      type: SAVE_DONATION_CHECKOUT_START,
      // payload: {
      //   creditCardId,
      //   creditCardType
      // }
    });

    const organization =
      parseInt(donationInformation.type) == 2 ?
      {
        organizationName: donationInformation.organizationName,
        organizationTitle: donationInformation.organizationTitle
      } :
      null;

    // TODO: Wait new flow to know what to do with this.
    const plugins = undefined;
    // const plugins = donationInformation.taxReceipt ?
    //   {
    //     taxReceipt: true
    //   } :
    //   undefined;

    const taxReceiptInfo = true /*plugins*/ ?
      {
        address: donationInformation.address || undefined,
        unit: donationInformation.unit && donationInformation.unit.length > 0 ?
          donationInformation.unit :
          undefined,
        city: donationInformation.city || undefined,
        province: donationInformation.province || undefined
      } :
      null;

    const body = {
      reCAPTCHA: donationInformation.reCAPTCHA || null,
      donationTypeId: parseInt(donationInformation.type),
      message: donationInformation.message,
      anonymous: donationInformation.anonymous || false,
      // payment: {
      //   stripe: {
      //     cardTokenId: cardTokenId
      //   }
      // },
      cardTokenId: cardTokenId,
      billingAnchor: donationInformation.when,
      ...getCheckoutTarget(donationTarget),
      newsletter: {
        platform: donationInformation.hasOwnProperty('bulletinOptin')
          ? donationInformation.bulletinOptin
            ? donationInformation.bulletinOptin
            : false
          : undefined,
        languageId: parseInt(donationInformation.locale)
      },
      transaction: {
        amount: moneyToNumber(donationInformation.amount),
        information: {
          ...organization,
          honorificId: parseInt(donationInformation.gender),
          firstName: donationInformation.firstname,
          lastName: donationInformation.lastname,
          email: donationInformation.email,
          country: donationInformation.country,
          ...taxReceiptInfo,
          postalCode: donationInformation.postalCode,
        }
      },
      //saveInformation: donationInformation.saveInformation || undefined,
      plugins
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      `/donations/subscription`,
      body,
      headers
    )
      .then(donation => {
        if (donation.transaction.status === 'APPROVED') {
          dispatch({
            type: SAVE_DONATION_CHECKOUT_SUCCESS,
            payload: donation
          });
        } else {
          dispatch({
            type: SAVE_DONATION_CHECKOUT_DECLINED,
            payload: donation
          });
        }
        if (body.saveInformation) {
          dispatch(updateSessionState());
        }
      })
      .catch(error => {
        return dispatch({
          type: SAVE_DONATION_CHECKOUT_ERROR,
          error
        });
      });
  };
}

export function createOfflineDonation(formValues) {
  return dispatch => {
    dispatch({
      type: SAVE_DONATION_CREATE_OFFLINE_START
    });

    const values = {
      donationTypeId: get(formValues, 'donation_type', undefined),
      // message: undefined, // not in the form yet
      anonymous: get(formValues, 'anonymous_donation', false),
      // creditCardId: undefined, // not in the form yet
      target: {
        platformId: get(formValues, 'recipient.platform.id', undefined),
        campaignId: get(formValues, 'recipient.campaign.id', undefined),
        teamId: get(formValues, 'recipient.team.id', undefined),
        participantId: get(formValues, 'recipient.participant.id', undefined),
      },
      newsletter: {
        languageId: get(formValues, 'languageId', undefined),
        platform: false
      },
      transaction: {
        amount: moneyToNumber(get(formValues, 'transaction_amount', 0)),
        paymentTypeId: get(formValues, 'payment_method', undefined),
        date: get(formValues, 'transaction_date', undefined),
        information: {
          organizationTitle: (get(formValues, 'donation_type', undefined) === 2 && get(formValues, 'job_title', undefined)) || undefined,
          organizationName: (get(formValues, 'donation_type', undefined) === 2 && get(formValues, 'business_name', undefined)) || undefined,
          honorificId: get(formValues, 'title', undefined),
          firstName: get(formValues, 'first_name', undefined),
          lastName: get(formValues, 'last_name', undefined),
          email: get(formValues, 'email_address', undefined),
          country: get(formValues, 'country', undefined),
          province: get(formValues, 'province', undefined),
          city: get(formValues, 'city', undefined),
          address: get(formValues, 'address', undefined),
          unit: get(formValues, 'unit', undefined),
          postalCode: get(formValues, 'postalCode', undefined),
        }
      }
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      '/donations/offline',
      values,
      headers
    )
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:donation-saved')
        });
        return dispatch({
          type: SAVE_DONATION_CREATE_OFFLINE_SUCCESS,
          payload
        });
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:creation-failed'),
          description: error.description || error
        });
        return dispatch({
          type: SAVE_DONATION_CREATE_OFFLINE_ERROR,
          error
        });
      });
  };
}

export function fetchLastDonationsByPlatformId(platformId) {
  return dispatch => {

    dispatch({ type: FETCH_DONATION_LIST_BY_PLATFORM_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/donations`,
      {
        orderBy: "addedOn[desc]",
        filterBy: "message[*]",
        pageResults: 50
      },
      headers
    )
      .then(result => {
        const payload = result.results.map(donation => ({
          ...donation
        }));

        dispatch({
          type: FETCH_DONATION_LIST_BY_PLATFORM_SUCCESS,
          payload
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_DONATION_LIST_BY_PLATFORM_ERROR,
          error
        });
      });
  };
}
export const fetchPlatformIdBySurveyList = (platformId, campaignId, ticketIds = [], formName, locale) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEBY_PLATFORMBYID_START });
    let url = `/survey/platform/${platformId}?lang_id=${locale}`;
    if (campaignId !== null && campaignId !== undefined) {
      url = `/survey/platform/${platformId}/?lang_id=${locale}&campaignId=${campaignId}&ticketIds=${JSON.stringify(ticketIds)}&form=${formName}`;
    }
    requestApiGet(url).then((response) => {
      return dispatch({ type: FETCH_SURVEBY_PLATFORMBYID_SUCCESS, payload: response.data });
    }).catch((error) => {
      return dispatch({
        type: FETCH_SURVEBY_PLATFORMBYID_ERROR,
        error: error,
      });
    });
  };
};

export function fetchLastDonationsByCampaignId(campaignId) {
  return dispatch => {
    dispatch({ type: FETCH_DONATION_LIST_BY_CAMPAIGN_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/donations`,
      { orderBy: "addedOn[desc]", pageResults: 50 },
      headers
    )
      .then(result => {
        const payload = result.results.map(donation => ({
          ...donation
        }));
        return dispatch({
          type: FETCH_DONATION_LIST_BY_CAMPAIGN_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_DONATION_LIST_BY_CAMPAIGN_ERROR,
          error
        });
      });
  };
}

export function fetchLastDonationsByTeamId(teamId) {
  return dispatch => {
    dispatch({ type: FETCH_DONATION_LIST_BY_TEAM_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${teamId}/donations`,
      { orderBy: "addedOn[desc]", pageResults: 50 },
      headers
    )
      .then(result => {
        const payload = result.results.map(donation => ({
          ...donation
        }));

        return dispatch({
          type: FETCH_DONATION_LIST_BY_TEAM_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_DONATION_LIST_BY_TEAM_ERROR,
          error
        });
      });
  };
}

export function fetchLastDonationsByParticipantId(platformId, participantId) {
  return dispatch => {
    dispatch({ type: FETCH_DONATION_LIST_BY_PARTICIPANT_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/participants/${participantId}/donations`,
      { orderBy: "addedOn[desc]", pageResults: 50 },
      headers
    )
      .then(result => {
        const payload = result.results.map(donation => ({
          ...donation
        }));

        return dispatch({
          type: FETCH_DONATION_LIST_BY_PARTICIPANT_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_DONATION_LIST_BY_PARTICIPANT_ERROR,
          error
        });
      });
  };
}

export function fetchTransactionById(transactionID, authentication = true) {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTION_BY_ID_START });

    const params = {
      //showExtra: true
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/transactions/${transactionID}`,
      params,
      headers
    )
      .then(result => {
        return dispatch({
          type: FETCH_TRANSACTION_BY_ID_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TRANSACTION_BY_ID_ERROR,
          error
        });
      });
  };
}

export function fetchTransactionLogs(id) {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTION_LOGS_START });

    const headers = {
      authentication: 'true',
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/transactions/${id}/logs`,
      { pageResults: 100 },
      headers
    )
      .then(logs => {
        return dispatch({
          type: FETCH_TRANSACTION_LOGS_SUCCESS,
          payload: logs
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TRANSACTION_LOGS_ERROR,
          error
        });
      });
  };
}

export function updateTransaction(formValues, transactionId, donationId) {
  return dispatch => {
    dispatch({
      type: UPDATE_TRANSACTION_START
    });

    const amount = formValues.transaction_amount
      ? { amount: formValues.transaction_amount }
      : null;

    const anonymous = formValues.anonymous_donation === 1
      ? true
      : formValues.anonymous_donation === 0
        ? false
        : undefined;

    const donationValues = {
      donationTypeId: formValues.donation_type || undefined,
      message: formValues.message && formValues.message.length > 0
        ? formValues.message
        : formValues.message === ''
          ? ''
          : undefined,
      anonymous: anonymous || (anonymous === false ? false : undefined),
      languageId: formValues.languageId || undefined,
      target: formValues.target || undefined
    };

    const transactionValues = {
      organizationTitle: formValues.job_title || undefined,
      organizationName: formValues.business_name || undefined,
      honorificId: formValues.title || undefined,
      firstName: formValues.first_name || undefined,
      lastName: formValues.last_name || undefined,
      email: formValues.email_address || undefined,
      country: formValues.country || undefined,
      province: formValues.province || undefined,
      city: formValues.city || undefined,
      address: formValues.address || undefined,
      unit: formValues.unit && formValues.unit.length > 0
        ? formValues.unit
        : formValues.unit === ''
          ? ''
          : undefined,
      postalCode: formValues.postalCode || undefined,
    };

    for (let donationProp1 in donationValues) {
      if (donationValues[donationProp1] === undefined) {
        delete donationValues[donationProp1];
      }
    }

    let isDonationEmpty = true;
    for (let donationProp2 in donationValues) {
      if (donationValues.hasOwnProperty(donationProp2)) {
        isDonationEmpty = false;
      }
    }

    for (let transactionProp1 in transactionValues) {
      if (transactionValues[transactionProp1] === undefined) {
        delete transactionValues[transactionProp1];
      }
    }

    let isTransactionEmpty = true;
    for (let transactionProp2 in transactionValues) {
      if (transactionValues.hasOwnProperty(transactionProp2)) {
        isTransactionEmpty = false;
      }
    }

    if (!isDonationEmpty || !isTransactionEmpty || amount) {
      const headers = {
        ...dispatch(getSessionIp())
      };

      return Promise.all([
        ...(donationValues && !isDonationEmpty
          ? [requestApiPut(
              `/donations/${donationId}`,
              donationValues,
              headers
            )]
          : []
        ),
        ...(transactionValues && !isTransactionEmpty
          ? [requestApiPut(
              `/transactions/${transactionId}/information`,
              transactionValues,
              headers
            )]
          : []
        ),
        ...(amount
          ? [requestApiPut(
              `/transactions/${transactionId}`,
              amount,
              headers
            )]
          : []
        )
      ])
        .then(result => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved')
          });
          dispatch(fetchTransactionById(transactionId));
          return dispatch({
            type: UPDATE_TRANSACTION_SUCCESS,
            payload: result
          });
        })
        .catch(error => {
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: error.description || error
          });
          return dispatch({
            type: UPDATE_TRANSACTION_ERROR,
            error
          });
        });
    } else {
      return dispatch({
        type: UPDATE_TRANSACTION_ERROR,
        error: 'Nothing to update.'
      });
    }
  };
}

export function requestTransactionUpdate(memberId, transactionId, reviewReason) {
  return dispatch => {
    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      `/members/${memberId}/transactions/${transactionId}/review`,
      { reviewReason },
      headers
    )
      .then(() => {
        notification.success({ message: i18next.t('API_NOTIFICATIONS:success'), description: i18next.t('API_NOTIFICATIONS:request-sent') });
      })
      .catch(error => {
        notification.error({ message: i18next.t('API_NOTIFICATIONS:request-failed'), description: error.description || error });
      });
  }
}

export function storeDonationInformation(formValues) {
  return {
    type: UI_STORE_DONATION_INFORMATION,
    payload: formValues
  };
}

export function storeUserInformation(formValues) {
  return {
    type: UI_STORE_USER_INFORMATION,
    payload: formValues
  };
}

export function selectOrganization(organization) {
  return {
    type: UI_DONATION_ORGANIZATION_SELECT,
    payload: organization
  };
}

export function selectCampaign(campaign) {
  return {
    type: UI_DONATION_CAMPAIGN_SELECT,
    payload: campaign
  };
}

export function selectTeam(team) {
  return {
    type: UI_DONATION_TEAM_SELECT,
    payload: team
  };
}

export function selectParticipant(participant) {
  return {
    type: UI_DONATION_PARTICIPANT_SELECT,
    payload: participant
  };
}

export function clearDonation() {
  return {
    type: UI_DONATION_CLEAR
  };
}

export function cancelTransaction() {
  return {
    type: CANCEL_DONATION
  };
}

export function clearTransaction() {
  return {
    type: CLEAR_TRANSACTION
  };
}
