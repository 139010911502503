import { notification } from "antd/lib/index";
import i18next from 'i18next';
import {
  isEmpty,
  get
} from 'lodash';

import {
  requestApiGet,
  requestApiPost,
  requestApiPut,
  requestApiDelete
} from "Parts/common/requestUtils";
import { getSessionIp } from "Parts/session/sessionUtils";
import { getLanguageIdByKey } from 'Parts/common/langUtils';

export const FETCH_TEAM_START = 'FETCH_TEAM_START';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_ERROR = 'FETCH_TEAM_ERROR';

export const VERIFY_TEAMS_START = 'VERIFY_TEAMS_START';
export const VERIFY_TEAMS_SUCCESS = 'VERIFY_TEAMS_SUCCESS';
export const VERIFY_TEAMS_ERROR = 'VERIFY_TEAMS_ERROR';

export const FETCH_TEAM_DESCRIPTION_START = 'FETCH_TEAM_DESCRIPTION_START';
export const FETCH_TEAM_DESCRIPTION_SUCCESS = 'FETCH_TEAM_DESCRIPTION_SUCCESS';
export const FETCH_TEAM_DESCRIPTION_ERROR = 'FETCH_TEAM_DESCRIPTION_ERROR';

export const FETCH_TEAM_SETTINGS_START = 'FETCH_TEAM_SETTINGS_START';
export const FETCH_TEAM_SETTINGS_SUCCESS = 'FETCH_TEAM_SETTINGS_SUCCESS';
export const FETCH_TEAM_SETTINGS_ERROR = 'FETCH_TEAM_SETTINGS_ERROR';

export const FETCH_TEAM_LOGS_START = 'FETCH_TEAM_LOGS_START';
export const FETCH_TEAM_LOGS_SUCCESS = 'FETCH_TEAM_LOGS_SUCCESS';
export const FETCH_TEAM_LOGS_ERROR = 'FETCH_TEAM_LOGS_ERROR';

export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_ERROR = 'FETCH_TEAMS_ERROR';

export const FETCH_TEAM_PARTICIPANTS_START = 'FETCH_TEAM_PARTICIPANTS_START';
export const FETCH_TEAM_PARTICIPANTS_SUCCESS = 'FETCH_TEAM_PARTICIPANTS_SUCCESS';
export const FETCH_TEAM_PARTICIPANTS_ERROR = 'FETCH_TEAM_PARTICIPANTS_ERROR';

export const SAVE_TEAM_CREATE_START = 'SAVE_TEAM_CREATE_START';
export const SAVE_TEAM_CREATE_SUCCESS = 'SAVE_TEAM_CREATE_SUCCESS';
export const SAVE_TEAM_CREATE_ERROR = 'SAVE_TEAM_CREATE_ERROR';

export const UI_TEAM_CAMPAIGN_SELECT = 'UI_TEAM_CAMPAIGN_SELECT';
export const UI_TEAM_CAMPAIGN_CLEAR = 'UI_TEAM_CAMPAIGN_CLEAR';

export const FETCH_TEAM_CAPTAIN_START = 'FETCH_TEAM_CAPTAIN_START';
export const FETCH_TEAM_CAPTAIN_SUCCESS = 'FETCH_TEAM_CAPTAIN_SUCCESS';
export const FETCH_TEAM_CAPTAIN_ERROR = 'FETCH_TEAM_CAPTAIN_ERROR';

export const UPDATE_TEAM_START = 'UPDATE_TEAM_START';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_ERROR = 'UPDATE_TEAM_ERROR';

export const CLEAR_TEAM = 'CLEAR_TEAM';

export function fetchTeams(campaignId, authentication = false, _params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_TEAMS_START
    });

    const params = {
      pageResults: 999999,
      ..._params
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/teams`,
      params,
      headers
    )
      .then(result => {
        // TODO the cast to number in the API
        const payload = result.results.map(team => ({
          ...team,
          goal: Number(get(team, 'goal', 0).toString().replace('NaN', 0)),
          collected: Number(get(team, 'collected', 0).toString().replace('NaN', 0))
        }));
        return dispatch({
          type: FETCH_TEAMS_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAMS_ERROR,
          error
        });
      });
    };
  }

  export function verifyTeamNames(teamNames, campaignId) {
    return dispatch => {
      dispatch({
        type: VERIFY_TEAMS_START
      });
      
      return requestApiPost(
        `/campaigns/${campaignId}/availability`,
        teamNames
      )
      .then(result => {
        // TODO the cast to number in the API
        const payload = result;
        return dispatch({
          type: VERIFY_TEAMS_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        return dispatch({
          type: VERIFY_TEAMS_ERROR,
          error
        });
      });
    };
}

export function fetchTeamMembers(teamId) {
  return dispatch => {
    dispatch({ type: FETCH_TEAM_PARTICIPANTS_START });

    const params = {
      pageResults: 999999
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${teamId}/participants`,
      params,
      headers
    )
      .then(result => {
        return dispatch({
          type: FETCH_TEAM_PARTICIPANTS_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_PARTICIPANTS_ERROR,
          error
        });
      });
  };
}

export function fetchTeam(idOrAlias, campaignId = null, authentication = false, params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_TEAM_START
    });

    //NB: campaignId is required only if team Alias is used.

    // PARAMS =>
    // showDescription: true
    // showSettings: true
    // showLeader: true
    // showChildrenCount: true

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${idOrAlias}${campaignId ? `/${campaignId}`: ""}`,
      params,
      headers
    )
      .then(team => {
        // TODO the cast to Number in the API
        const payload = {
          ...team,
          collected: Number(get(team, 'collected', 0).toString().replace('NaN', 0))
        };

        return dispatch({
          type: FETCH_TEAM_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_ERROR,
          error
        });
      });
  };
}

export function fetchTeamDescription(id, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_TEAM_DESCRIPTION_START
    });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${id}/description`,
      null,
      headers
    )
      .then(description => {
        return dispatch({
          type: FETCH_TEAM_DESCRIPTION_SUCCESS,
          payload: description
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_DESCRIPTION_ERROR,
          error
        });
      });
  };
}

export function fetchTeamSettings(id, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_TEAM_SETTINGS_START
    });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${id}/settings`,
      null,
      headers
    )
      .then(settings => {
        return dispatch({
          type: FETCH_TEAM_SETTINGS_SUCCESS,
          payload: settings
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_SETTINGS_ERROR,
          error
        });
      });
  };
}

export function fetchTeamLogs(id) {
  return dispatch => {
    dispatch({ type: FETCH_TEAM_LOGS_START });

    const headers = {
      authentication: 'true',
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${id}/logs`,
      { pageResults: 100 },
      headers
    )
      .then(logs => {
        return dispatch({
          type: FETCH_TEAM_LOGS_SUCCESS,
          payload: logs
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_LOGS_ERROR,
          error
        });
      });
  };
}

export function selectCampaign(campaign) {
  return {
    type: UI_TEAM_CAMPAIGN_SELECT,
    payload: campaign
  };
}

export function clearSelectedCampaign() {
  return {
    type: UI_TEAM_CAMPAIGN_CLEAR
  };
}

export function createTeam(formValues, campaignId) {
  return dispatch => {
    dispatch({
      type: SAVE_TEAM_CREATE_START
    });

    const values = {
      ...formValues,
      campaignId
    };

    // delete values.communicationApproval; // TODO Should we store that ? (we store the acceptedTermsConditions)

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      '/teams',
      values,
      headers
    )
      .then(team => {
        dispatch({
          type: SAVE_TEAM_CREATE_SUCCESS,
          payload: team
        });
      })
      .catch(error => {
        const description = error.description || error;
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:creation-failed'),
          description: description
        });
        return dispatch({
          type: SAVE_TEAM_CREATE_ERROR,
          error: error
        });
      });
  };
}

export function fetchTeamCaptain(teamId) {
  return dispatch => {
    dispatch({
      type: FETCH_TEAM_CAPTAIN_START
    });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/team/captain/${teamId}`,
      null,
      headers
    )
      .then(captain => {
        dispatch({
          type: FETCH_TEAM_CAPTAIN_SUCCESS,
          payload: captain
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_CAPTAIN_ERROR,
          error: error
        });
      });
  };
}

export function getCaptain(userId, participants) {
  let captain = {};
  for (let i = 0, len = participants.length; i < len; i++) {
    if (participants[i].userId == userId) {
      captain = participants[i];
      break;
    }
  }
  return captain;
}

export function updateTeam(formValues, teamId, locale = 'en') {
  return dispatch => {
    dispatch({
      type: UPDATE_TEAM_START
    });

    const description =
      formValues.captain_message === '' || formValues.captain_message ?
      [{
        description: formValues.captain_message === '<p><br></p>' ? null : formValues
          .captain_message,
        languageId: getLanguageIdByKey(locale)
      }] :
      null;

    const values = {
      name: formValues.team_name || undefined,
      alias: formValues.team_url || undefined,
      leaderId: formValues.team_captain || undefined
    };

    let key;
    for (key in values) {
      if (values[key] === undefined) {
        delete values[key];
      }
    }

    const headers = {
      ...dispatch(getSessionIp())
    };

    return Promise.all([
      ...(!isEmpty(values)
        ? [requestApiPut(
            `/teams/${teamId}`,
            values,
            headers
          )]
        : []
      ),
      ...(!isEmpty(description) && description[0].description
        ? [requestApiPut(
            `/teams/${teamId}/description`,
            description,
            headers
          )]
        : []
      ),
      ...(!isEmpty(description) && description[0].description === null
        ? [requestApiDelete(
            `/teams/${teamId}/description`,
            { languageId: description[0].languageId },
            headers
          )]
        : []
      )
    ])
      .then(updated => {
        return Promise.all([
          dispatch(fetchTeam(teamId, null, true, {
            showLeader: true
          })),
          dispatch(fetchTeamDescription(teamId, true)),
          dispatch(fetchTeamSettings(teamId, true))
        ]);
      })
      .then(fetched => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: UPDATE_TEAM_SUCCESS
        });
      })
      .catch(error => {
        const description = error.description || error;
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: description
        });
        return dispatch({
          type: UPDATE_TEAM_ERROR,
          error: error
        });
      });
  };
}

export function clearTeam() {
  return dispatch => {
    dispatch({
      type: CLEAR_TEAM
    });
  };
}
